<template>
    <fhiry-page :banners="banners">
        <div class="cooperation">
            <div class="case-box">
                <fhiry-case
                    class="case-item"
                    v-for="(caseItem, i) in cases"
                    :data="caseItem"
                    :key="i"
                />
            </div>
        </div>
    </fhiry-page>
</template>

<script>
    // 合作案列
    // 组件
    import FhiryPage from '@/components/FhiryPage';
    import FhiryCase from '@/components/FhiryCase';
    // api
    import { getCooperation } from '@/api/request';

    export default {
        name: 'cooperation',
        created () {
            this.getCooperationAjax();
        },
        data () {
            return {
                cases: [],
                banners: []
            }
        },
        methods: {
            getCooperationAjax () {
                getCooperation().then(res => {
                    this.banners = res.banners;
                    this.cases = res.cooperation.map(item => {
                        return {
                            ...item,
                            caseItem: item.title
                        }
                    });
                })
            }
        },
        components: {
            FhiryPage,
            FhiryCase
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .cooperation {
        padding: 20px 0;
        .case-box {
            margin: 20px auto;
            width: @limit-width;
            .case-item {
                margin-bottom: 20px;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .cooperation {
            padding: 0.2666rem 0;
            .case-box {
                margin: 0.2666rem auto;
                padding: 0 0.2666rem;
                width: 100%;
                .case-item {
                    margin-bottom: 0.2666rem;
                }
            }
        }
    }
</style>