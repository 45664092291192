<template>
    <div class="fhiry-carousel">
        <!-- pc -->
        <el-carousel class="carousel">
            <el-carousel-item v-for="(item, index) in data" :key="index">
                <div class="pc-banner">
                    <img :src="item.img" alt="" />
                    <div class="pc-banner-content" v-if="item.type != void 0">
                        <h1>{{ item.title }}</h1>
                        <div class="text item" v-if="item.type == 'text'">
                            <p v-for="(it, i) in item.text" :key="i">{{ it }}</p>
                        </div>
                        <ul class="list item" v-if="item.type == 'list'">
                            <li v-for="(it, i) in item.text" :key="i">{{ it }}</li>
                        </ul>
                    </div>
                </div>  
            </el-carousel-item>
        </el-carousel>
        <!-- 手机-平板 -->
        <swipe class="carousel-phone" indicator-color="white" :autoplay="3000">
            <swipe-item v-for="(item, index) in data" :key="index">
                <div class="phone-banner">
                    <img :src="item.img" alt="" />
                    <div class="phone-banner-content" v-if="item.type != void 0">
                        <h1>{{ item.title }}</h1>
                        <div class="text item" v-if="item.type == 'text'">
                            <p v-for="(it, i) in item.text" :key="i">{{ it }}</p>
                        </div>
                        <ul class="list item" v-if="item.type == 'list'">
                            <li v-for="(it, i) in item.text" :key="i">{{ it }}</li>
                        </ul>
                    </div>
                </div> 
            </swipe-item>
        </swipe>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    // 组件
    import { Swipe, SwipeItem } from 'vant';

    export default {
        name: 'fhiry-carousel',
        props: {
            data: {
                type: Array,
                default () {
                    return [];
                }
            }
        },
        computed: {
            ...mapState([
                'imgBaseURL'
            ])
        },
        components: {
            Swipe,
            SwipeItem
        }
    }
</script>

<style lang="less">
    .fhiry-carousel {
        .carousel {
            height: 460px;
            .el-carousel__container {
                height: 100%;
                .el-carousel__item {
                    height: 100%;
                    .pc-banner {
                        height: 100%;
                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
            .pc-banner {
                position: relative;
                .pc-banner-content {
                    position: absolute;
                    top: 20%;
                    left: 10%;
                    z-index: 999;
                    h1 {
                        font-size: 0.7rem;
                        font-weight: bold;
                    }
                    div, ul {
                        position: relative;
                        left: 4px;
                        list-style: disc inside;
                        p, li {
                            line-height: 2;
                            font-size: 0.2666rem;
                        }
                    }
                }
            }
        }
        .carousel-phone {
            display: none;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1150px) {
        .fhiry-carousel {
            .carousel {
                height: 280px;
            }
        } 
    }

    @media screen and (min-width: 1151px) and (max-width: 1320px) {
        .fhiry-carousel {
            .carousel {
                height: 310px;
            }
        } 
    }

    @media screen and (min-width: 1321px) and (max-width: 1440px) {
        .fhiry-carousel {
            .carousel {
                height: 340px;
            }
        } 
    }

    @media screen and (min-width: 1441px) and (max-width: 1620px) {
        .fhiry-carousel {
            .carousel {
                height: 380px;
            }
        } 
    }

    @media screen and (min-width: 1621px) and (max-width: 1750px) {
        .fhiry-carousel {
            .carousel {
                height: 420px;
            }
        }
    }

    // 手机适配
    @media screen and (max-width: 1023px) {
        .fhiry-carousel {
            .carousel {
                display: none;
            }
            .carousel-phone {
                display: block;
                .van-swipe__track {
                    height: 100%;
                    .phone-banner {
                        position: relative;
                        height: 100%;
                        img {
                            height: 100%;
                            width: 100%;
                        }
                        .phone-banner-content {
                            position: absolute;
                            top: 14%;
                            left: 10%;
                            z-index: 999;
                            h1 {
                                font-size: 0.7rem;
                                font-weight: bold;
                            }
                            div, ul {
                                position: relative;
                                left: 4px;
                                list-style: disc inside;
                                p, li {
                                    line-height: 2;
                                    font-size: 0.2666rem;
                                }
                            }
                        }
                    }
                }
            }
        }   
    }

    @media screen and (min-width: 900px) and (max-width: 1023px) {
        .fhiry-carousel {
            .carousel-phone {
                height: 240px;
            }
        }   
    }

    @media screen and (min-width: 768px) and (max-width: 899px) {
        .fhiry-carousel {
            .carousel-phone {
                height: 220px;
            }
        }  
    }

    @media screen and (min-width: 500px) and (max-width: 767px) {
        .fhiry-carousel {
            .carousel-phone {
                height: 160px;
            }
        }  
    }

    @media screen and (max-width: 499px) {
        .fhiry-carousel {
            .carousel-phone {
                height: 110px;
                .van-swipe__track {
                    height: 100%;
                    .phone-banner {
                        .phone-banner-content {
                            position: absolute;
                            top: 4%;
                            left: 2%;
                            z-index: 999;
                            h1 {
                                font-size: 0.3rem;
                                font-weight: bold;
                            }
                            div, ul {
                                left: 0px;
                                p, li {
                                    line-height: 1.5;
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }  
    }
</style>