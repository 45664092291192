<template>
    <div class="case-item">
        <div class="left">
            <img :src="`${imgBaseURL}${data.img}`" alt="">
        </div>
        <div class="right">
            <h4>{{ data.caseItem }}</h4>
            <p>{{ data.desc }}</p>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'case-item',
        props: {
            data: {
                type: Object,
                default () {
                    return {
                        img: '',
                        caseItem: '',
                        desc: ''
                    }
                }
            }
        },
        computed: {
            ...mapState([
                'imgBaseURL'
            ])
        }
    }
</script>

<style lang="less" scoped>
    .case-item {
        display: flex;
        align-items: center;
        padding: 24px;
        background-color: #f7f7f7;
        border-radius: 5px;
        transition: box-shadow 0.3s;
        cursor: pointer;
        &:hover {
            background-color: #FFF;
            box-shadow: 0 0 10px #999;
        }
        .left {
            width: 400px;
            img {
                width: 100%;
            }
        }
        .right {
            margin-left: 24px;
            width: calc(~'100% - 24px');
            h4 {
                font-size: 18px;
                font-weight: bold;
            }
            p {
                margin-top: 16px;
                color: #999;
                font-size: 14px;
                line-height: 1.5;
            }
        }
    }

    // 移动端
    @media screen and (max-width: 1023px) {
        .case-item {
            flex-direction: column;
            .left {
                order: 2;
                width: auto;
            }
            .right {
                order: 1;
                margin-left: 0;
                margin-bottom: 0.21333rem;
                h4 {
                    font-size: 0.37333rem;
                }
                p {
                    margin-top: 0.21333rem;
                }
            }
        }
    }
</style>