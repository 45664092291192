<template>
    <div class="fhiry-page">
        <header class="fhiry-page-header">
            <fhiry-carousel :data="banners" />
        </header>
        <div class="content">
            <slot />
        </div>
    </div>    
</template>

<script>
    import FhiryCarousel from './FhiryCarousel';

    export default {
        name: 'fhiry-page',
        props: {
            banners: {
                type: Array,
                default () {
                    return [];
                }
            }
        },
        components: {
            FhiryCarousel
        }
    }
</script>

<style lang="less" scoped>
    .fhiry-page {
        .content {
            background-color: #f1f1f1;
        }
    }
</style>